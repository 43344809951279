import { PersonData } from './person-data';
import { Role } from './role';
import { Industry } from './industry';
import { Country } from './country';
import { WeightConfiguration } from './weight-coniguration';

export class FileContent {
    personDataList: PersonData[] = [];
    countryList: Country[] = [];
    industryList: Industry[] = [];
    roleList: Role[] = [];
    configuration: WeightConfiguration = new WeightConfiguration(50, 50, 50, 50, 50);
}
