import { Injectable } from '@angular/core';
import { FileContent } from '../models/file-content';
import { BaseInformation } from '../models/base-information';

@Injectable()
export class ConfigurationService {

    loadPreConfigured(fileContent: FileContent) {
        fileContent.configuration.connections = 3;
        fileContent.configuration.recommendations = 3;
        fileContent.configuration.country = 20;
        fileContent.configuration.industry = 40;
        fileContent.configuration.role = 70;

        this.setEmptyInfoWeight(fileContent, 3);

        this.applyWeightByWord('president', fileContent.roleList, 95);
        this.applyWeightByWord('manag', fileContent.roleList, 90);
        this.applyWeightByWord('direct', fileContent.roleList, 85);
        this.applyWeightByWord('coord', fileContent.roleList, 80);

        this.applyWeightByWord('united states', fileContent.countryList, 55);
        this.applyWeightByWord('canada', fileContent.countryList, 54);
        this.applyWeightByWord('germany', fileContent.countryList, 53);
        this.applyWeightByWord('brazil', fileContent.countryList, 52);
        this.applyWeightByWord('argentina', fileContent.countryList, 51);


        this.applyWeightByWord('computer', fileContent.industryList, 60);
        this.applyWeightByWord('technology', fileContent.industryList, 59);
        this.applyWeightByWord('telecom', fileContent.industryList, 58);
        this.applyWeightByWord('game', fileContent.industryList, 57);        
    }

    setEmptyInfoWeight(fileContent: FileContent, weight: number) {
        fileContent.countryList.filter(x => x.name == '-').forEach(x => x.weight = weight);
        fileContent.roleList.filter(x => x.name == '-').forEach(x => x.weight = weight);
        fileContent.industryList.filter(x => x.name == '-').forEach(x => x.weight = weight);
    }

    applyWeightByWord(word: string, list: BaseInformation[], weight: number) {
        word = word.toLocaleLowerCase();
        list.filter(x => x.lowerName.indexOf(word) > -1).forEach(x => x.weight = weight);
    }
}