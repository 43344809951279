import { Language } from '../language';
import { LocaleType } from '../locale-type';

export let Locale: LocaleType = {
    code: Language.EN,

    title: {
        fileSelection: 'Selection of Source File',
        weightConfiguration: 'Weight Configuration',
        infoConfiguration: 'Configuration of Parameters',
        results: 'Top 100 Potential Clients',
    },

    button: {
        next: 'Next',
        previous: 'Previous',
        exportResults: 'Export Results',
        selectFile: 'Select File',
        loadPreConfigured: 'Load pre-analyzed Configuration'        
    },

    label: {
        selectClients: 'Select Clients',
        recommendations: 'Recommendations',
        connections: 'Connections',
        country: 'Country',
        role: 'Role',
        industry: 'Industry',
        weight: 'Weight',
        personCount: 'Person Count',
        weightSelector: 'Weight Selector',
        results: 'Results',
    },

    startMessage: `Hello! This is a simple application, as result of a Code Challenge, to select, from a list of public 
        information about some potential customer, which of them would be the 100 most suitable for sending an email marketing.
        <br><br>
        To use this application, you need to have the file same model as 'people.in', available when the Code Challenge was
        introduced. The general flow of this application is:
        <ul>
            <li>Select the file</li>
            <li>Define importance and each type of information</li>
            <li>Define importance of information within each type of information</li>
            <li>Displaying and exporting results</li>
        </ul>       
        The selection of candidates is done in a simple way, assigning a score to each candidate based on the configuration made, and
        listing the 100 candidates with the highest score.
        <br><br>
        In the next step there is a button to load a pre-analyzed parametrization to highlight people with
        roles of influence in their companies, since the parameterization depends on the objective of the selection of these candidates, 
        and I do not have this information when developing this app.
        <br><br>
        The logic is relatively simple, but once I was informed that the position is for Angular 8 developer, I found it interesting to
        develop an analysis and configuration tool for the parameters, instead of simply configuring them.`
}