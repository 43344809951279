import { Component } from '@angular/core';
import { ParserService } from './services/parser.service';
import { FileContent } from './models/file-content';
import { SelectionService } from './services/selection.service';
import { PersonDataScore } from './models/person-data-score';
import { FileWriterService } from './services/file-writer.service';
import { I18n } from './i18n';
import { Language } from './i18n/language';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.less']
})
export class AppComponent {
	fileContent: FileContent;
	showInfoConfigurationFlag = false;
	sortedData: PersonDataScore[];

	constructor(
		private parserService: ParserService,
		private selectionService: SelectionService,
		private fileWriterService: FileWriterService,
		public i18n: I18n
	) { }

	selectFile(fileString: string) {
		this.fileContent = this.parserService.getFileContent(fileString);
	}

	previous() {
		if (!!this.sortedData) {
			this.sortedData = null;
		} else if (this.showInfoConfigurationFlag) {
			this.showInfoConfigurationFlag = false;
		} else if (!!this.fileContent) {
			this.fileContent = null;
		}
	}

	next() {
		if (this.showWeightConfiguration()) {
			this.showInfoConfigurationFlag = true;
		} else if (this.showInfoConfiguration()) {
			return this.generateScore();
		} 
	}

	exportResults() {
		this.fileWriterService.exportResults(this.sortedData);
	}

	generateScore() {
		let selection = this.selectionService.sortData(this.fileContent);
		let top100 = [];
		for (let i = 0; i < selection.length && i < 100; i++) {
			selection[i].position = i + 1;
			top100.push(selection[i]);
		}
		this.sortedData = top100;
	}

	getTitle() {
		if (this.showFileSelection()) {
			return this.i18n.t.title.fileSelection;
		} else if (this.showWeightConfiguration()) {
			return this.i18n.t.title.weightConfiguration;
		} else if (this.showInfoConfiguration()) {
			return this.i18n.t.title.infoConfiguration;
		} else {
			return this.i18n.t.title.results;
		}
	}

	showFileSelection() {
		return !this.fileContent;
	}

	showWeightConfiguration() {
		return !!this.fileContent && !this.showInfoConfigurationFlag;
	}

	showInfoConfiguration() {
		return this.showInfoConfigurationFlag && !this.sortedData;
	}

	showResults() {
		return !!this.sortedData;
	}

	EN() {
		this.i18n.changeLanguage(Language.EN);
	}

	ES() {
		this.i18n.changeLanguage(Language.ES);
	}

	PT() {
		this.i18n.changeLanguage(Language.PT);
	}
}
