import { Component, AfterViewInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { FileContent } from 'src/app/models/file-content';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { ParserService } from 'src/app/services/parser.service';
import { SelectionService } from 'src/app/services/selection.service';
import { I18n } from 'src/app/i18n';

@Component({
	selector: 'app-select-file',
	templateUrl: './select-file.component.html',
	styleUrls: ['./select-file.component.less']
})
export class SelectFileComponent implements AfterViewInit {

    @ViewChild('fileInput', { static: false }) fileInput: ElementRef;	
	@BlockUI() blockUI: NgBlockUI;
	@Output() selectFile : EventEmitter<string> = new EventEmitter();
	
	constructor(public i18n: I18n) { }

	ngAfterViewInit() {
		this.fileInput.nativeElement.onchange = () => this.readFile();
	}

	readFile() {
		this.blockUI.start();
		this.readFileInputAsString()
			.then(content =>  {
				this.selectFile.emit(content);
				this.fileInput.nativeElement.value = '';
			})
			.catch(err => this.fileInput.nativeElement.value = '')
			.then(() => this.blockUI.stop());
	}

	private readFileInputAsString() : Promise<string> {
		return new Promise((resolve, reject) => {
			let files = this.fileInput.nativeElement.files;
			if (!files || !files.length || files.length < 1) {
				this.fileInput.nativeElement.value = '';
				reject();
			}
			let file = files[0];			
			if (file.name) {
				let reader: FileReader = new FileReader();
				reader.onloadend = (s) => {				
					let content = s.target['result'] as string;	
					resolve(content);
				}
				reader.readAsText(file);
			}		
		});		
	}

	openFileSelector() {
		this.fileInput.nativeElement.click();
	}
}