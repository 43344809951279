import { Industry } from './industry';
import { Country } from './country';
import { Role } from './role';

export class PersonData {
    currentRole: Role;
    country: Country;
    industry: Industry; 
    constructor(
        public id: string,
        public name: string,
        public lastName: string,
        public recommendations: number,
        public connections: number
    ){}
}