import { Language } from '../language';
import { LocaleType } from '../locale-type';

export let Locale: LocaleType = {
    code: Language.ES,

    title: {
        fileSelection: 'Selección de archivo de datos',
        weightConfiguration: 'Configuración de peso',
        infoConfiguration: 'Configuración de parámetros',
        results: 'Los 100 clientes potenciales principales',
    },

    button: {
        next: 'Siguiente',
        previous: 'Volver',
        exportResults: 'Resultados de exportación',
        selectFile: 'Seleccionar archivo',
        loadPreConfigured: 'Análisis preconfigurado de carga'        
    },

    label: {
        selectClients: 'Identificación del cliente',
        recommendations: 'Recomendaciones',
        connections: 'Conexiones',
        country: 'Pais',
        role: 'Posición',
        industry: 'Industria',
        weight: 'Peso',
        personCount: 'Cantidad de personas',
        weightSelector: 'Clasificación de peso',
        results: 'Resultados',
    },

    startMessage: `Hola Esta es una aplicación simple, el resultado de un Code Challenge, para seleccionar, de una lista de información 
        pública sobre algunos clientes potenciales, cuáles serían las 100 más adecuados para enviar un correo electrónico de marketing.
        <br><br>
        Para usar esta aplicación, debe tener el archivo modelo 'people.in', disponible cuando se presentó el Code Challenge. 
        El flujo general de esta aplicación es:
        <ul>
            <li>Selecciona el archivo</li>
            <li>Definir la importancia de cada categoría</li>
            <li>Definir la importancia de la información dentro de cada categoría</li>
            <li>Mostrar y exportar resultados</li>
        </ul>       
        La selección de candidatos se realiza de manera simple, asignando una puntuación a cada candidato en función de la 
        configuración realizada y enumerando los 100 candidatos con la puntuación más alta.
        <br><br>
        En el siguiente paso, hay un botón para cargar una parametrización previamente analizada que destaca a las personas 
        con posiciones de influencia en sus empresas, ya que la parametrización depende de cuál es el 
        objetivo de la selección de estos candidatos, y no tengo esta información al desarrollar esta aplicación.
        <br><br>
        La lógica es relativamente simple, pero una vez que me informaron que la vacante es para el desarrollador de Angular 8, 
        me pareció interesante establecer una herramienta de análisis y configuración para los parámetros, en lugar de simplemente configurarlos.`
}