
import { Component, Input } from '@angular/core';
import { BaseInformation } from 'src/app/models/base-information';
import { I18n } from 'src/app/i18n';


@Component({
	selector: 'app-base-information-config',
	templateUrl: './base-information-config.component.html',
	styleUrls: ['./base-information-config.component.less']
})
export class BaseInformationConfigComponent {
	@Input() info: BaseInformation;
	constructor(public i18n: I18n) { }
}