import { Component, Input } from '@angular/core';
import { PersonDataScore } from 'src/app/models/person-data-score';
import { I18n } from 'src/app/i18n';

@Component({
	selector: 'app-show-results',
	templateUrl: './show-results.component.html',
	styleUrls: ['./show-results.component.less']
})
export class ShowResultsComponent {
	@Input() sortedData: PersonDataScore[];
	constructor(public i18n: I18n) { }
}