
import { Component, Input } from '@angular/core';
import { PersonDataScore } from 'src/app/models/person-data-score';
import { I18n } from 'src/app/i18n';

@Component({
	selector: 'app-person-score',
	templateUrl: './person-score.component.html',
	styleUrls: ['./person-score.component.less']
})
export class PersonScoreComponent {
    @Input() personScore: PersonDataScore;
    constructor(public i18n: I18n) { }
}