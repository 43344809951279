import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BlockUIModule } from 'ng-block-ui';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SliderModule } from 'primeng/slider';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ParserService } from './services/parser.service';
import { SelectionService } from './services/selection.service';
import { I18n } from './i18n';
import { SelectFileComponent } from './components/select-file/select-file.component';
import { ShowResultsComponent } from './components/show-results/show-results.component';
import { ParametersConfigurationComponent } from './components/parameter-configuration/parameter-configuration.component';
import { BaseInformationConfigComponent } from './components/base-information-config/base-information-config.component';
import { InfoWeightConfigComponent } from './components/info-weight-config/info-weight-config.component';
import { PersonScoreComponent } from './components/person-score/person-score.component';
import { ConfigurationService } from './services/configuration.service';
import { FileWriterService } from './services/file-writer.service';

@NgModule({
  declarations: [
    AppComponent,
    SelectFileComponent,
    ShowResultsComponent,
    ParametersConfigurationComponent,
    BaseInformationConfigComponent,
    InfoWeightConfigComponent,
    PersonScoreComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BlockUIModule.forRoot(),
    SliderModule,
    ScrollingModule
  ],
  providers: [
    ParserService,
    SelectionService,
    ConfigurationService,
    FileWriterService,
    I18n
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
