import { Language } from '../language';
import { LocaleType } from '../locale-type';

export let Locale: LocaleType = {
    code: Language.PT,

    title: {
        fileSelection: 'Seleção do Arquivo de Dados',
        weightConfiguration: 'Configuração de Pesos',
        infoConfiguration: 'Configuração de Parâmetros',
        results: 'Top 100 Clientes Potenciais',
    },

    button: {
        next: 'Avançar',
        previous: 'Voltar',
        exportResults: 'Exportar Resultados',
        selectFile: 'Selecione o Arquivo',
        loadPreConfigured: 'Carregar Análise Pré-configurada'        
    },

    label: {
        selectClients: 'Identificação de Clientes',
        recommendations: 'Recomendações',
        connections: 'Conexões',
        country: 'País',
        role: 'Cargo',
        industry: 'Indústria',
        weight: 'Peso',
        personCount: 'Número de Pessoas',
        weightSelector: 'Classificação de Pesos',
        results: 'Resultados',
    },

    startMessage: `Olá! Este é um aplicativo simples, resultado de um Code Challenge, para selecionar, dentre uma lista de informações
        públicas sobre alguns potenciais clientes, quais deles seriam os 100 mais indicados para enviar um e-mail marketing.
        <br><br>
        Para utilizar este aplicativo, você precisa ter o arquivo do modelo 'people.in', diponibilizado quando o Code Challenge foi
        apresentado. O fluxo geral deste aplicativo é:
        <ul>
            <li>Selecionar o arquivo</li>
            <li>Definir importância de cada categoria</li>
            <li>Definir importância das informações dentro de cada categoria</li>
            <li>Exibir e exportar resultados</li>
        </ul>       
        A seleção dos candidatos é feita de forma simples, atribuindo uma pontuação a cada candidato baseado na configuração feita, e 
        elencando os 100 candidatos com maior pontuação.
        <br><br>
        No próximo passo existe um botão para carregar uma parametrização pré-analizada que dá destaque a pessoas com 
        cargos de influência em suas empresas, uma vez que a parametrização depende de qual o objetivo da seleção desses candidatos, e não possuo
        esta informação no momento de desenvolver este aplicativo.
        <br><br>
        A lógica é relativamente simples, mas uma vez que me informaram que a vaga é para desenvolvedor Angular 8, achei interessante
        montar uma ferramenta de análise e configuração dos parâmetros, ao invés de simplesmente configurá-los.`
}