
import { Component, Input } from '@angular/core';
import { FileContent } from 'src/app/models/file-content';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { I18n } from 'src/app/i18n';

@Component({
	selector: 'app-info-weight-config',
	templateUrl: './info-weight-config.component.html',
	styleUrls: ['./info-weight-config.component.less']
})
export class InfoWeightConfigComponent {
    @Input() fileContent: FileContent;
    @BlockUI() blockUI: NgBlockUI;

    constructor(private configurationService: ConfigurationService,public i18n: I18n){}
    
    loadPreConfiguration() {
        this.blockUI.start();
        setTimeout(() => {
            this.configurationService.loadPreConfigured(this.fileContent);
            this.blockUI.stop();
        }, 1);        
    }
}