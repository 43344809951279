import { PersonData } from './person-data';

export class PersonDataScore {
    personData: PersonData;
    
    score: number;
    recommendations: number;
    connections: number;
    country: number;
    industry: number;
    role: number;

    position: number;
}