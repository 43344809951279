import { Injectable } from '@angular/core';
import { FileContent } from '../models/file-content';
import { PersonData } from '../models/person-data';
import { Country } from '../models/country';
import { Industry } from '../models/industry';
import { Role } from '../models/role';

@Injectable()
export class ParserService {
    private PERSON_SEPARATOR = '\n';
    private INFO_SEPARATOR = '|';
    private infoDictionary = {
        id: 0,
        name: 1,
        lastName: 2,
        currentRole: 3,
        country: 4,
        industry: 5,
        recommendations: 6,
        connections: 7
    };

    getFileContent(contentFile: string, personSeparator: string = null): FileContent {
        personSeparator = personSeparator ? personSeparator : this.PERSON_SEPARATOR;

        let fileContent = new FileContent();
        let contentLines = contentFile.split(personSeparator);
        contentLines.forEach(line => this.addLine(fileContent, line));

        fileContent.countryList = fileContent.countryList.sort((a, b) => !!a.name ? a.name.localeCompare(b.name) : 0);
        fileContent.roleList = fileContent.roleList.sort((a, b) => !!a.name ? a.name.localeCompare(b.name) : 0);
        fileContent.industryList = fileContent.industryList.sort((a, b) => !!a.name ? a.name.localeCompare(b.name) : 0);
        return fileContent;
    }

    private addLine(fileContent: FileContent, line: string, infoSeparator: string = null) {
        infoSeparator = infoSeparator ? infoSeparator : this.INFO_SEPARATOR;

        line = line.trim();
        let lineInfoList = line.split(infoSeparator);

        let personData = new PersonData (
            lineInfoList[this.infoDictionary.id],
            lineInfoList[this.infoDictionary.name],
            lineInfoList[this.infoDictionary.lastName],
            this.parseNumber(lineInfoList[this.infoDictionary.recommendations]),
            this.parseNumber(lineInfoList[this.infoDictionary.connections]),
        );
        personData.country = this.getCountry(fileContent, lineInfoList[this.infoDictionary.country]);
        personData.currentRole = this.getRole(fileContent, lineInfoList[this.infoDictionary.currentRole]);
        personData.industry = this.getIndustry(fileContent, lineInfoList[this.infoDictionary.industry]);

        fileContent.personDataList.push(personData);
    }

    private getCountry(fileContent: FileContent, name: string) {
        name = !!name ? name : '-';
        if(!fileContent.countryList) fileContent.countryList = [];
        let model = fileContent.countryList.find(x => x.name == name);
        
        if(!model){
            model = new Country(name, name.toLocaleLowerCase());
            fileContent.countryList.push(model);
        }      
        model.personCount++;
        return model;
    }

    private getRole(fileContent: FileContent, name: string) {
        name = !!name ? name : '-';
        if(!fileContent.roleList) fileContent.countryList = [];
        let model = fileContent.roleList.find(x => x.name == name);
        
        if(!model){
            model = new Role(name, name.toLocaleLowerCase());
            fileContent.roleList.push(model);
        }   
        model.personCount++;
        return model;
    }

    private getIndustry(fileContent: FileContent, name: string) {
        name = !!name ? name : '-';
        if(!fileContent.industryList) fileContent.countryList = [];
        let model = fileContent.industryList.find(x => x.name == name);
        
        if(!model){
            model = new Industry(name, name.toLocaleLowerCase());
            fileContent.industryList.push(model);
        }
        model.personCount++;
        return model;
    }

    private parseNumber(value: string):number {
        return Number(value);
    }

}