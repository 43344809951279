import { Injectable } from '@angular/core';
import { FileContent } from '../models/file-content';
import { PersonDataScore } from '../models/person-data-score';
import { PersonData } from '../models/person-data';
import { WeightConfiguration } from '../models/weight-coniguration';

@Injectable()
export class SelectionService {

    sortData(fileContent: FileContent) : PersonDataScore[] {
        let scoreList = fileContent.personDataList.map(personData => this.getScore(personData, fileContent.configuration));
        scoreList = scoreList.sort((scoreA, scoreB) => scoreA.score < scoreB.score ? 1 : -1);
        return scoreList;
    }

    private getScore(personData: PersonData, configuration: WeightConfiguration) : PersonDataScore {
        let score = new PersonDataScore();
        score.personData = personData;    
                
        let personValues = {
            recommendations: !!personData.recommendations && !isNaN(personData.recommendations) ? personData.recommendations : 0,
            connections: !!personData.connections && !isNaN(personData.connections) ? personData.connections : 0,
            country: !!personData.country ? personData.country.weight : 0,
            role: !!personData.currentRole ? personData.currentRole.weight : 0,
            industry: !!personData.industry ? personData.industry.weight : 0,
        }

        score.recommendations = personValues.recommendations * configuration.recommendations;
        score.connections = personValues.connections * configuration.connections;
        score.country = personValues.country * configuration.country;
        score.role = personValues.role * configuration.role;
        score.industry = personValues.industry * configuration.industry;

        score.score = (score.recommendations)
            + (score.connections)
            + (score.country)
            + (score.role)
            + (score.industry);

        return score; 
    }
    
}