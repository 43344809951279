import { Injectable } from "@angular/core";
import { LocaleType } from "./locale-type";
import { Locale as Locale_PT } from './PT';
import { Locale as Locale_EN } from './EN';
import { Locale as Locale_ES } from './ES';
import { Language } from './language';
import { StorageHandler } from '../storage/storage.handler';
import { StorageTable } from '../storage/storage-table';


@Injectable()
export class I18n {

    t: LocaleType;
    options = [Locale_PT, Locale_EN, Locale_ES];
    static current: I18n;

    constructor() {
        let locs = StorageHandler.List(StorageTable.i18n);
        let code = this.getBrowserCode();
        if (locs.length > 0) {
            code = locs[0].code;
        }
        I18n.current = this;
        this.t = this.options.filter(o => { return o.code == code })[0];
    }

    changeLanguage(code: Language) { 
        let selected = this.options.find(o => { return o.code == code });
        if (selected) {
            this.t = selected;
            StorageHandler.DeleteTable(StorageTable.i18n);
            StorageHandler.Save(StorageTable.i18n, { code: code });        
        }        
    }

    getBrowserCode() {
        return  window.navigator.language.indexOf('pt') > -1 ? Locale_PT.code :
                window.navigator.language.indexOf('es') > -1 ? Locale_ES.code :
                Locale_EN.code;
    }

    extend(moduleName: string, locale: any, code?: Language) {
        let selected = this.options.find(o => { return o.code == code });
        selected[moduleName] = locale;
    }

}