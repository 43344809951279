import { Injectable } from '@angular/core';
import { PersonDataScore } from '../models/person-data-score';

@Injectable()
export class FileWriterService {

    exportResults(sortedData: PersonDataScore[]) {
        let idList = sortedData.map(x => x.personData.id);
        let content = idList.join('\n');
        FileWriterService.write(content, 'people.out');
    }

    static write(content: string, name: string) {
        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=UTF-8,' + encodeURIComponent(content);
        hiddenElement.target = '_blank';
        hiddenElement.download = name;
        hiddenElement.click();
    }

}