
import { Component, Input } from '@angular/core';
import { FileContent } from 'src/app/models/file-content';
import { BaseInformation } from 'src/app/models/base-information';
import { I18n } from 'src/app/i18n';


@Component({
	selector: 'app-parameter-configuration',
	templateUrl: './parameter-configuration.component.html',
	styleUrls: ['./parameter-configuration.component.less']
})
export class ParametersConfigurationComponent {
	@Input() fileContent: FileContent;
	constructor(public i18n: I18n) { }

}